<template>
  <section>
    <p style="padding: 10px">
      <b>O Painel COVID-19</b> é um Sistema de Informação Geográfica
      desenvolvido pelo
      <b>Laboratório de Pesquisa, Inovação e Software(LAPIS)</b> do
      <b>IFCE - campus Tabuleiro do Norte</b>. O objetivo do sistema é permitir
      que os municípios do Estado do Ceará possam cadastrar, gerenciar e
      acompanhar os casos de COVID-19 em tempo real e georreferenciados. O setor
      de vigilância epidemiológica dos municípios é responsável por registrar os
      casos de COVID-19, visando simplificar o acesso, disponibilização e
      consultas aos registros sobre o COVID-19. Os profissionais de saúde e a
      população podem consultar os dados por meio de tabelas, gráficos e mapas
      dinâmicos. Os <b>municípios</b> podem enviar as seguintes informações
      (nome completo do usuário, e-mail, quantidade de leitos e respiradores no
      município, nome da cidade e lista com o nome de todos os bairros urbanos)
      por
      <a
        href="mailto:lapislab@ifce.edu.br?Subject=Desejo acesso ao sistema Painel Epidemiologico"
        target="_blank"
        >email</a
      >
      para requisitar acesso ao sistema.
    </p>
    <hr />
    <h4>Professores</h4>
    <div class="grid-card">
      <div class="card" v-for="(item, index) in docente" :key="index">
        <h5 style="text-align: center;">{{ item.name }}</h5>
        <span>{{ item.institute }}</span>
        <span>{{ item.role }}</span>
      </div>
    </div>
    <hr />
    <h4>Desenvolvedores</h4>
    <div class="grid-card">
      <div class="card" v-for="(item, index) in desenvolvedores" :key="index">
        <h5 style="text-align: center;">{{ item.name }}</h5>
        <span>{{ item.role }}</span>
        <a :href="item.github" target="_blank" class="font-weight-light mb-0"
          >Github</a
        >
      </div>
    </div>
    <hr />
    <h3 class="text-center">Apoio</h3>
    <div class="text-center">
      <!-- <img
        src="../assets/img/lapisifcelogo.png"
        style="width: 800px; height: auto"
      /> -->

      <hr class="divider" />
      <img
        class="mb-5 col-md-3"
        src="../assets/img/lapis-logo.png"
        style="width: 320px; height: auto;"
      />
      <img
        class="mb-5 col-md-3"
        src="../assets/img/logo-ifce.png"
        style="width: 320px; height: auto;"
      />
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      desenvolvedores: [
        {
          name: "Carlos Gardel Saldanha Moura Júnior",
          role: "Desenvolvedor de Software - LAPIS-IFCE",
          github: "https://github.com/GardelJunior"
        },
        {
          name: "Luan Douglas Maia da Silva",
          role: "Desenvolvedor de Software - LAPIS-IFCE",
          github: "https://github.com/luandouglas"
        },
        {
          name: "Marcelo dos Santos Estevam",
          role: "Desenvolvedor de Software - LAPIS-IFCE",
          github: "https://github.com/estevam96"
        },
         {
          name: "Gessé Fernandes Martins",
          role: "Desenvolvedor de Software - LAPIS-IFCE",
          github: "https://github.com/gesse-fernandes"
        }
      ],
      docente: [
        {
          name: "Jarbas Nunes Vidal Filho",
          institute: "Professor do IFCE - Campus Tabuleiro do Norte",
          role: "Coordenador do LAPIS - IFCE"
          // github: "https://github.com/luandouglas"
        }
      ]
    };
  }
};
</script>
<style scoped>
.lista {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
</style>
